import React from 'react';

interface ILogoIconProps {
  height?: string;
}

const LogoIcon: React.FC<ILogoIconProps> = ({ height = '36px' }) => {
  return (
    <svg
      // width="166"
      height={height}
      viewBox="0 0 166 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_640_5989)">
        <path
          d="M53.3904 22.7879V18.3637C54.2585 18.3637 54.9406 18.1213 55.4367 17.697C55.9328 17.2728 56.3048 16.7273 56.5529 16.0001C56.8009 15.2728 56.9869 14.5455 57.111 13.697C57.235 12.8485 57.359 12.0607 57.421 11.2122C57.545 9.93945 57.6691 8.72733 57.7931 7.57582C57.8551 6.4243 57.9171 5.21218 57.9791 4.00006C58.0411 2.78794 58.1031 1.394 58.1651 -0.0605469H70.009V18.3637H71.3732V22.7879H70.195V19.5152H54.5686V22.7879H53.3904V22.7879ZM56.4289 18.4243H68.8308V1.15157H59.2813C59.2193 2.3637 59.1573 3.57582 59.0953 4.72733C59.0333 5.87885 58.9713 7.09097 58.8472 8.30309C58.7852 9.51521 58.6612 10.7273 58.5372 11.9395C58.4132 13.0304 58.2892 14.0001 58.1651 14.8485C57.9791 15.697 57.7931 16.4243 57.545 17.0304C57.235 17.6364 56.8629 18.0607 56.4289 18.4243Z"
          fill="#902215"
        />
        <path d="M74.7217 19.5758V0H75.8999V19.5758H74.7217Z" fill="#902215" />
        <path
          d="M80.0544 19.5758V0H81.1706L89.6659 18.1818L98.0993 0H99.2774V19.5152H98.0993V2.72727L90.348 19.5152H89.0458L81.2326 2.72727V19.5152H80.0544V19.5758Z"
          fill="#902215"
        />
        <path
          d="M62.6918 46.0004C60.7075 46.0004 58.9712 45.5761 57.545 44.7276C56.1188 43.8791 55.0026 42.667 54.2585 41.1519C53.5144 39.6367 53.1423 37.8185 53.1423 35.8185C53.1423 33.8185 53.5144 32.0004 54.2585 30.4852C55.0026 28.9701 56.1188 27.7579 57.545 26.9094C58.9712 26.061 60.7075 25.6367 62.6918 25.6367C64.6761 25.6367 66.4124 26.061 67.8386 26.9094C69.2649 27.7579 70.381 28.9701 71.1251 30.4852C71.8693 32.0004 72.2413 33.8185 72.2413 35.8185C72.2413 37.8185 71.8693 39.6367 71.1251 41.1519C70.381 42.667 69.2649 43.8791 67.8386 44.7276C66.4124 45.5761 64.6761 46.0004 62.6918 46.0004ZM62.6918 42.9094C64.056 42.9094 65.1722 42.6064 66.0404 42.061C66.9085 41.4549 67.5906 40.667 68.0247 39.5761C68.4587 38.4852 68.7068 37.2731 68.7068 35.8185C68.7068 34.364 68.4587 33.1519 68.0247 32.061C67.5906 31.0307 66.9085 30.1822 66.0404 29.5761C65.1722 28.9701 64.056 28.667 62.6918 28.667C61.3276 28.667 60.2114 28.9701 59.3433 29.5155C58.4752 30.1216 57.7931 30.9094 57.359 32.0004C56.9249 33.0913 56.6769 34.3034 56.6769 35.7579C56.6769 37.2125 56.9249 38.4246 57.359 39.5155C57.7931 40.5458 58.4752 41.3943 59.3433 42.0004C60.2114 42.6064 61.3276 42.9094 62.6918 42.9094Z"
          fill="#902215"
        />
        <path
          d="M75.0317 45.5757V26.0605H91.2163V45.6363H87.8677V29.0908H78.3803V45.5757H75.0317Z"
          fill="#902215"
        />
        <path
          d="M99.9597 45.5757V29.0908H93.5107V26.0605H109.819V29.0908H103.37V45.5757H99.9597Z"
          fill="#902215"
        />
        <path
          d="M128.05 45.5757H124.639V31.8181L115.338 45.5757H111.927V26.0605H115.338V39.8181L124.639 26.0605H128.05V45.5757Z"
          fill="#902215"
        />
        <path
          d="M131.957 45.5757V26.0605H135.305V35.1515L143.305 26.0605H147.397L138.778 35.6363L147.955 45.5757H143.677L135.243 36.4242V45.5757H131.957V45.5757Z"
          fill="#902215"
        />
        <path
          d="M166 45.5757H162.589V31.8181L153.288 45.5757H149.877V26.0605H153.288V39.8181L162.589 26.0605H166V45.5757Z"
          fill="#902215"
        />
        <path
          d="M20.2772 44.9703C9.11543 44.9703 0 36.0613 0 25.1522C0 14.2431 9.11543 5.33398 20.2772 5.33398C31.4389 5.33398 40.5544 14.2431 40.5544 25.1522C40.4923 36.0613 31.4389 44.9703 20.2772 44.9703ZM20.2772 8.24308C10.7277 8.24308 2.97647 15.8188 2.97647 25.1522C2.97647 34.4855 10.7277 42.0613 20.2772 42.0613C29.8267 42.0613 37.5779 34.4855 37.5779 25.1522C37.5159 15.8188 29.7647 8.24308 20.2772 8.24308Z"
          fill="#902215"
        />
        <path
          d="M36.7719 1.45508V3.03084H38.3221V3.6975H36.7719V5.27326H36.0278V3.6975H34.4775V3.03084H36.0278V1.45508H36.7719Z"
          fill="#902215"
        />
        <path
          d="M37.702 6.18226H35.0976V4.6065H33.5474V2.12166H35.0976V0.545898H37.702V2.12166H39.1902V4.6065H37.702V6.18226Z"
          fill="#902215"
        />
      </g>
      <defs>
        <clipPath id="clip0_640_5989">
          <rect width="166" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
