import React from 'react';
import { toast } from 'react-toastify';

export const useConnection = () => {
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);

  React.useEffect(() => {
    const handleOnline = () => {
      toast.success("З'єднання відновлено");
      setIsOnline(true);
    };
    const handleOffline = () => {
      toast.warning("З'єднання втрачено");
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return { isOnline };
};
