import axios from 'axios';
import { toast } from 'react-toastify';

export async function clearCache() {
  try {
    const response: any = await axios.post('/admin/common/cache');
    toast.success('Кеш очищено');
    return response.data;
  } catch (error: any) {
    console.error(error);
    toast.error('Щось пішло не так. Спробуйте ще раз');
  }
}
