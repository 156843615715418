import React from 'react';
import InventoryIcon from '@mui/icons-material/Inventory';
import PeopleIcon from '@mui/icons-material/People';
import SourceIcon from '@mui/icons-material/Source';
import SettingsIcon from '@mui/icons-material/Settings';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
// import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
//-----------------Pages
// const Dashboard = React.lazy(() => import('../../Pages/Dashboard'));
const ProductsCatalogPage = React.lazy(
  () => import('../../Pages/CatalogPages/ProductsCatalogPage')
);
const AttributesPage = React.lazy(
  () => import('../../Pages/CatalogPages/AttributesPage')
);
const AttributesCategoriesPage = React.lazy(
  () => import('../../Pages/CatalogPages/AttributesCategoriesPage')
);
const OptionsPage = React.lazy(
  () => import('../../Pages/ContentPages/OptionsPage')
);
const OrdersPage = React.lazy(
  () => import('../../Pages/SalesPages/OrdersPage')
);
const ManufacturersPage = React.lazy(
  () => import('../../Pages/CatalogPages/ManufacturersPage')
);
// const ClientsPage = React.lazy(
//   () => import('../../Pages/ClientsPages/ClientsPage')
// );
const ProductsCategoriesPage = React.lazy(
  () => import('../../Pages/CatalogPages/ProductsCategoriesPage')
);
const NewsPage = React.lazy(() => import('../../Pages/ContentPages/NewsPage'));
const MainPageBanner = React.lazy(
  () => import('../../Pages/SettingsPages/MainPageBanner')
);
const MainPageBannerTop = React.lazy(
  () => import('../../Pages/SettingsPages/MainPageBannerTop')
);
const MainPageServices = React.lazy(
  () => import('../../Pages/SettingsPages/MainPageServices')
);
const MainPageSubscribe = React.lazy(
  () => import('../../Pages/SettingsPages/MainPageSubscribe')
);
const MenuFooterPage = React.lazy(
  () => import('../../Pages/SettingsPages/MenuFooterPage')
);
const MenuHeaderPage = React.lazy(
  () => import('../../Pages/SettingsPages/MenuHeaderPage')
);
const OrdersStatusesPage = React.lazy(
  () => import('../../Pages/SalesPages/OrdersStatusesPage')
);
const MobileMenuPage = React.lazy(
  () => import('../../Pages/SettingsPages/MobileMenuPage')
);
const SalesCategoriesPage = React.lazy(
  () => import('../../Pages/ContentPages/SalesCategoriesPage')
);
const SalesPage = React.lazy(
  () => import('../../Pages/ContentPages/SalesPage')
);
const NewsCategoriesPage = React.lazy(
  () => import('../../Pages/ContentPages/NewsCategoriesPage')
);
const StoresPage = React.lazy(
  () => import('../../Pages/ContentPages/StoresPage')
);
const SubscriptionPage = React.lazy(
  () => import('../../Pages/ClientsPages/SubscriptionsPage')
);
const FAQPage = React.lazy(() => import('../../Pages/ContentPages/FAQPage'));
const DoctorsPage = React.lazy(
  () => import('../../Pages/ContentPages/DoctorsPage')
);
const ProductReviewPage = React.lazy(
  () => import('../../Pages/ClientsPages/ProductReviewPage')
);
const ServicesPage = React.lazy(
  () => import('../../Pages/ContentPages/ServicesPage')
);
const InfoPagesPage = React.lazy(
  () => import('../../Pages/ContentPages/InfoPagesPage')
);
const SpecialModulePage = React.lazy(
  () => import('../../Pages/ModulesPages/SpecialModulePage')
);
const LanguagesPage = React.lazy(
  () => import('../../Pages/SettingsPages/LanguagesPage')
);
const CurrencyPage = React.lazy(
  () => import('../../Pages/SettingsPages/CurrencyPage')
);
const AvailablePage = React.lazy(
  () => import('../../Pages/SettingsPages/AvailablePage')
);
const StickersPage = React.lazy(
  () => import('../../Pages/CatalogPages/StickersPage')
);
const GiftModulePage = React.lazy(
  () => import('../../Pages/ModulesPages/GiftModulePage')
);
const PromoCodePage = React.lazy(
  () => import('../../Pages/SalesPages/PromoCodePage')
);
const OphthalmologistCallPage = React.lazy(
  () => import('../../Pages/ClientsPages/OphthalmologistCallPage')
);
const DoctorCallPage = React.lazy(
  () => import('../../Pages/ClientsPages/DoctorCallPage')
);
const ProductAvailableCallPage = React.lazy(
  () => import('../../Pages/ClientsPages/ProductAvailableCallPage')
);
const CitiesPage = React.lazy(
  () => import('../../Pages/ContentPages/CitiesPage')
);
const UsersPage = React.lazy(
  () => import('../../Pages/SettingsPages/UsersPage')
);
const TopModulePage = React.lazy(
  () => import('../../Pages/ModulesPages/TopModulePage')
);
const CustomersPage = React.lazy(
  () => import('../../Pages/ClientsPages/CustomersPage')
);
const CustomersGroupsPage = React.lazy(
  () => import('../../Pages/ClientsPages/CustomersGroupsPage')
);
const CallHeaderPage = React.lazy(
  () => import('../../Pages/ClientsPages/CallHeaderPage')
);
const PopupModulePage = React.lazy(
  () => import('../../Pages/ModulesPages/PopupModulePage')
);
const ConsultationPage = React.lazy(
  () => import('../../Pages/ClientsPages/ConsultationPage')
);
const TestimonialsPage = React.lazy(
  () => import('../../Pages/ClientsPages/TestimonialsPage')
);

//---------------------PagesData

const ProductData = React.lazy(() => import('../../PagesEditData/ProductData'));
const AttributesData = React.lazy(
  () => import('../../PagesEditData/AttributesData')
);
const OptionsData = React.lazy(() => import('../../PagesEditData/OptionsData'));
const ProductCategoryData = React.lazy(
  () => import('../../PagesEditData/ProductCategoryData')
);
const OrdersData = React.lazy(() => import('../../PagesEditData/OrdersData'));
const NewsData = React.lazy(() => import('../../PagesEditData/NewsData'));
const MainPageServicesData = React.lazy(
  () => import('../../PagesEditData/MainPageData/MainPageServicesData')
);
const MainPageSubscribeData = React.lazy(
  () => import('../../PagesEditData/MainPageData/MainPageSubscribeData')
);
const MainPageBannerTopData = React.lazy(
  () => import('../../PagesEditData/MainPageData/MainPageBannerTopData')
);
const MainPageBannerData = React.lazy(
  () => import('../../PagesEditData/MainPageData/MainPageBannerData')
);
const ManufacturersData = React.lazy(
  () => import('../../PagesEditData/ManufacturersData')
);
const AttributesCategoriesData = React.lazy(
  () => import('../../PagesEditData/AttributesCategoriesData')
);
const MenuFooterData = React.lazy(
  () => import('../../PagesEditData/MenuFooterData')
);
const MenuHeaderData = React.lazy(
  () => import('../../PagesEditData/MenuHeaderData')
);
const HeaderFooterData = React.lazy(
  () => import('../../PagesEditData/HeaderFooterData')
);
const SeoLinksData = React.lazy(
  () => import('../../PagesEditData/SeoLinksData')
);
const OrdersStatusesData = React.lazy(
  () => import('../../PagesEditData/OrdersStatusesData')
);
const MobileMenuData = React.lazy(
  () => import('../../PagesEditData/MenuMobileData/MobileMenuData')
);
const SalesCategoriesData = React.lazy(
  () => import('../../PagesEditData/SalesCategoriesData')
);
const SalesData = React.lazy(() => import('../../PagesEditData/SalesData'));
const NewsCategoriesData = React.lazy(
  () => import('../../PagesEditData/NewsCategoriesData')
);
const StoresData = React.lazy(() => import('../../PagesEditData/StoresData'));
const SubscriptionsData = React.lazy(
  () => import('../../PagesEditData/SubscriptionsData')
);
const FAQData = React.lazy(() => import('../../PagesEditData/FAQData'));
const GTMData = React.lazy(() => import('../../PagesEditData/GTMData'));
const RobotsTxtData = React.lazy(
  () => import('../../PagesEditData/RobotsTxtData')
);
const RedirectsData = React.lazy(
  () => import('../../PagesEditData/RedirectsData')
);
const DoctorsData = React.lazy(() => import('../../PagesEditData/DoctorsData'));
const ProductReviewData = React.lazy(
  () => import('../../PagesEditData/ProductReviewData')
);
const ServicesData = React.lazy(
  () => import('../../PagesEditData/ServicesData')
);
const InfoPagesData = React.lazy(
  () => import('../../PagesEditData/InfoPagesData')
);
const SpecialModuleData = React.lazy(
  () => import('../../PagesEditData/SpecialModuleData')
);
const LanguagesData = React.lazy(
  () => import('../../PagesEditData/LanguagesData')
);
const CurrencyData = React.lazy(
  () => import('../../PagesEditData/CurrencyData')
);
const AvailableData = React.lazy(
  () => import('../../PagesEditData/AvailableData')
);
const StickersData = React.lazy(
  () => import('../../PagesEditData/StickersData')
);
const GiftModuleData = React.lazy(
  () => import('../../PagesEditData/GiftModuleData')
);
const PromoCodesData = React.lazy(
  () => import('../../PagesEditData/PromoCodesData')
);
const OphthalmologistCallData = React.lazy(
  () => import('../../PagesEditData/OphthalmologistCallData')
);
const DoctorCallData = React.lazy(
  () => import('../../PagesEditData/DoctorCallData')
);
const ProductAvailableCallData = React.lazy(
  () => import('../../PagesEditData/ProductAvailableCallData')
);
const CitiesData = React.lazy(() => import('../../PagesEditData/CitiesData'));
const UsersData = React.lazy(() => import('../../PagesEditData/UsersData'));
const TopModuleData = React.lazy(
  () => import('../../PagesEditData/TopModuleData')
);
const MetaPixelData = React.lazy(
  () => import('../../PagesEditData/MetaPixelData')
);
const UpdateAttributesData = React.lazy(
  () => import('../../PagesEditData/UpdateAttributesData')
);
const PopupModuleData = React.lazy(
  () => import('../../PagesEditData/PopupModuleData')
);
const TopBannerData = React.lazy(
  () => import('../../PagesEditData/TopBannerData')
);
const ConsultationData = React.lazy(
  () => import('../../PagesEditData/ConsultationData')
);
const TestimonialsData = React.lazy(
  () => import('../../PagesEditData/TestimonialsData')
);

export const navBarMenuItems = [
  // {
  //   name: 'Дашборд',
  //   primaryLinkName: 'dashboard',
  //   id: 0,
  //   Icon: DashboardIcon,
  //   link: '/dashboard',
  //   Component: Dashboard,
  // },
  {
    name: 'Каталог',
    primaryLinkName: 'products',
    id: 1,
    Icon: InventoryIcon,
    items: [
      {
        name: 'Каталог товарів',
        link: '/products/productsCatalog',
        Component: ProductsCatalogPage,
        DataComponent: ProductData,
      },
      {
        name: 'Категорії товарів',
        link: '/products/productsCategories',
        Component: ProductsCategoriesPage,
        DataComponent: ProductCategoryData,
      },
      {
        name: 'Виробники',
        link: '/products/manufacturers',
        Component: ManufacturersPage,
        DataComponent: ManufacturersData,
      },
      {
        name: 'Атрибути',
        link: '/products/attributes',
        items: [
          {
            name: 'Атрибути',
            link: '/products/attributes',
            Component: AttributesPage,
            DataComponent: AttributesData,
          },
          {
            name: 'Категорії',
            link: '/products/attributesCategories',
            Component: AttributesCategoriesPage,
            DataComponent: AttributesCategoriesData,
          },
        ],
      },
      {
        name: 'Опції',
        link: '/products/options',
        Component: OptionsPage,
        DataComponent: OptionsData,
      },
      {
        name: 'Стікери',
        link: '/products/stickers',
        Component: StickersPage,
        DataComponent: StickersData,
      },
    ],
  },
  {
    name: 'Продажі',
    primaryLinkName: 'sales',
    id: 2,
    Icon: ShoppingCartIcon,
    items: [
      {
        name: 'Замовлення',
        link: '/sales/orders',
        Component: OrdersPage,
        DataComponent: OrdersData,
      },
      {
        name: 'Статуси',
        link: '/sales/statuses',
        Component: OrdersStatusesPage,
        DataComponent: OrdersStatusesData,
      },
      {
        name: 'Промокоди',
        link: '/sales/promoCodes',
        Component: PromoCodePage,
        DataComponent: PromoCodesData,
      },
    ],
  },
  {
    name: 'Клієнти',
    primaryLinkName: 'clients',
    id: 3,
    Icon: PeopleIcon,
    items: [
      {
        name: 'Клієнти',
        link: '/clients/clients',
        items: [
          {
            name: 'Клієнти',
            link: '/clients/customers',
            Component: CustomersPage,
            DataComponent: null,
          },
          {
            name: 'Категорії клієнтів',
            link: '/clients/customersGroups',
            Component: CustomersGroupsPage,
            DataComponent: null,
          },
        ],
      },
      {
        name: 'Підписки',
        link: '/clients/subscriptions',
        Component: SubscriptionPage,
        DataComponent: SubscriptionsData,
      },
      {
        name: 'Відгуки для продуктів',
        link: '/clients/reviews',
        Component: ProductReviewPage,
        DataComponent: ProductReviewData,
      },
      {
        name: 'Відгуки про сайт',
        link: '/clients/testimonials',
        Component: TestimonialsPage,
        DataComponent: TestimonialsData,
      },
      {
        name: 'Дзвінки хедер',
        link: '/clients/callHeader',
        Component: CallHeaderPage,
        DataComponent: null,
      },
      {
        name: 'Виклики офтальмолога',
        link: '/clients/ophthalmologistCall',
        Component: OphthalmologistCallPage,
        DataComponent: OphthalmologistCallData,
      },
      {
        name: 'Виклики лікаря',
        link: '/clients/doctorCall',
        Component: DoctorCallPage,
        DataComponent: DoctorCallData,
      },
      {
        name: 'Консультації',
        link: '/clients/consultations',
        Component: ConsultationPage,
        DataComponent: ConsultationData,
      },
      {
        name: 'Повідомити про наявність',
        link: '/clients/productAvailableCall',
        Component: ProductAvailableCallPage,
        DataComponent: ProductAvailableCallData,
      },
    ],
  },
  {
    name: 'Контент',
    primaryLinkName: 'content',
    id: 4,
    Icon: SourceIcon,
    items: [
      {
        name: 'Послуги',
        link: '/content/services',
        Component: ServicesPage,
        DataComponent: ServicesData,
      },
      {
        name: 'Інформаційні сторінки',
        link: '/content/infoPages',
        Component: InfoPagesPage,
        DataComponent: InfoPagesData,
      },
      {
        name: 'Акції',
        link: '/content/actions',
        items: [
          {
            name: 'Акції',
            link: '/content/actions',
            Component: SalesPage,
            DataComponent: SalesData,
          },
          {
            name: 'Категорії акцій',
            link: '/content/actionsCategories',
            Component: SalesCategoriesPage,
            DataComponent: SalesCategoriesData,
          },
        ],
      },
      {
        name: 'Блог',
        link: '/content/news',
        items: [
          {
            name: 'Статті',
            link: '/content/news',
            Component: NewsPage,
            DataComponent: NewsData,
          },
          {
            name: 'Категорії статтей',
            link: '/content/newsCategories',
            Component: NewsCategoriesPage,
            DataComponent: NewsCategoriesData,
          },
        ],
      },
      {
        name: "FAQ's",
        link: '/content/FAQ',
        Component: FAQPage,
        DataComponent: FAQData,
      },
      {
        name: 'Лікарі',
        link: '/content/doctors',
        Component: DoctorsPage,
        DataComponent: DoctorsData,
      },
      {
        name: 'Магазини',
        link: '/content/shops',
        Component: StoresPage,
        DataComponent: StoresData,
      },
      {
        name: 'Міста',
        link: '/content/stores',
        Component: CitiesPage,
        DataComponent: CitiesData,
      },
    ],
  },
  {
    name: 'Налаштування',
    primaryLinkName: 'settings',
    id: 5,
    Icon: SettingsIcon,
    items: [
      {
        name: 'Головна сторінка',
        link: '/settings/mainPage',
        items: [
          {
            name: 'Банер',
            link: '/settings/mainPage/banner',
            Component: MainPageBanner,
            DataComponent: MainPageBannerData,
          },
          {
            name: 'Банер для неї/нього',
            link: '/settings/mainPage/bannerTop',
            Component: MainPageBannerTop,
            DataComponent: MainPageBannerTopData,
          },
          {
            name: 'Сервіси',
            link: '/settings/mainPage/services',
            Component: MainPageServices,
            DataComponent: MainPageServicesData,
          },
          {
            name: 'Підписка',
            link: '/settings/mainPage/subscribe',
            Component: MainPageSubscribe,
            DataComponent: MainPageSubscribeData,
          },
          {
            name: 'Топ продажів та акційні товари',
            link: '/modules/topModule',
            Component: TopModulePage,
            DataComponent: TopModuleData,
          },
        ],
      },
      {
        name: 'Хедер/Футер',
        link: '/settings/headerFooter',
        Component: HeaderFooterData,
        DataComponent: null,
      },
      {
        name: 'Мови',
        link: '/settings/languages',
        Component: LanguagesPage,
        DataComponent: LanguagesData,
      },
      {
        name: 'Валюти',
        link: '/settings/currency',
        Component: CurrencyPage,
        DataComponent: CurrencyData,
      },
      // {
      //   name: 'Статуси товару',
      //   link: '/settings/available',
      //   Component: AvailablePage,
      //   DataComponent: AvailableData,
      // },
      {
        name: 'Меню',
        link: '/settings/menu',
        items: [
          {
            name: 'Меню у хедері',
            link: '/settings/menu/menuHeader',
            Component: MenuHeaderPage,
            DataComponent: MenuHeaderData,
          },
          {
            name: 'Меню у футері',
            link: '/settings/menu/menuFooter',
            Component: MenuFooterPage,
            DataComponent: MenuFooterData,
          },
          {
            name: 'Мобільне меню',
            link: '/settings/menu/menuMobile',
            Component: MobileMenuPage,
            DataComponent: MobileMenuData,
          },
        ],
      },
      {
        name: 'Користувачі',
        link: '/settings/users',
        Component: UsersPage,
        DataComponent: UsersData,
      },
    ],
  },
  {
    name: 'Модулі',
    primaryLinkName: 'modules',
    id: 6,
    Icon: RoomPreferencesIcon,
    items: [
      {
        name: 'Масові знижки',
        link: '/modules/specialModule',
        Component: SpecialModulePage,
        DataComponent: SpecialModuleData,
      },
      {
        name: 'Масове заповнення атрибутів',
        link: '/modules/updateAttributes',
        Component: UpdateAttributesData,
        DataComponent: null,
      },
      {
        name: 'Подарунки',
        link: '/modules/giftModule',
        Component: GiftModulePage,
        DataComponent: GiftModuleData,
      },
      {
        name: 'Попап в каталозі',
        link: '/modules/popupModule',
        Component: PopupModulePage,
        DataComponent: PopupModuleData,
      },
      {
        name: 'Топ банер',
        link: '/modules/topBanner',
        Component: TopBannerData,
        DataComponent: null,
      },
      {
        name: 'SEO лінки',
        link: '/modules/seoLinks',
        Component: SeoLinksData,
        DataComponent: null,
      },
      {
        name: 'SEO redirects',
        link: '/modules/seoRedirects',
        Component: RedirectsData,
        DataComponent: null,
      },
      {
        name: 'GTM',
        link: '/modules/gtm',
        Component: GTMData,
        DataComponent: null,
      },
      {
        name: 'Meta pixel',
        link: '/modules/metaPixel',
        Component: MetaPixelData,
        DataComponent: null,
      },
      {
        name: 'robots.txt',
        link: '/modules/robots',
        Component: RobotsTxtData,
        DataComponent: null,
      },
    ],
  },
];
