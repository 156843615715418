import Flmngr from '@flmngr/flmngr-react';
import FlmngrCreateParams from '@flmngr/flmngr-react';

const storage = process.env.REACT_APP_FILE_MANAGER_STORAGE_URL;
const server = process.env.REACT_APP_FILE_MANAGER_SERVER_URL;
const apiKey = process.env.REACT_APP_FILE_MANAGER_API_KEY;

export const stopInputScroll = (e: any) => {
  (e.target as HTMLInputElement).blur();
  e.stopPropagation();
  setTimeout(() => {
    (e.target as HTMLInputElement).focus();
  }, 0);
};

export const Status = {
  idle: 'IDLE',
  pending: 'PENDING',
  resolved: 'RESOLVED',
  rejected: 'REJECTED',
};

export const haveSameData = function (obj1: any, obj2: any) {
  const result = JSON.stringify(obj1) === JSON.stringify(obj2);
  return result;
};

export const loadFileManager = () => {
  const options: FlmngrCreateParams = {
    isDynamicDirListing: false,
    whiteLabel: true,
    apiKey: apiKey,
    urlFileManager: server,
    urlFiles: storage,
  };
  Flmngr.load(options);
};

export const getAltText = (url: string) => {
  const separatedArray = url.split('/');
  const altText = separatedArray[separatedArray.length - 1].split('.')[0];
  return altText;
};

export const requiredText = "Це поле обов'язкове";
export const notValidEmailText = 'Неправильний формат пошти';
export const shortPassword = 'Пароль занадто короткий';
export const notValidSlug =
  'Це поле може включати лише малі літери, цифри, "-" та "_"';

export const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const slugRegex = /^[a-z0-9-_]+$/;

export async function copyTextToClipboard(text?: string) {
  if (text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }
}
