import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import { useHeaderStyles } from './Header.styles';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Modal from '../Modal';
import { fetchUserData, token } from '../../services/commonAPI/authAPI';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LogoIcon from '../LogoIcon';
import { fetchNewNotifications } from '../../services/commonAPI/notificationsAPI';
import { clearCache } from '../../services/commonAPI/cacheAPI';
import { Skeleton } from '@mui/material';

interface HeaderProps {
  toggleDrawer: (open: boolean) => void;
  openDrawer: boolean;
  darkTheme: boolean;
  setLoggedIn: (user: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({
  toggleDrawer,
  openDrawer,
  darkTheme,
  setLoggedIn,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { classes, cx } = useHeaderStyles();
  const [newNotifications, setNewNotifications] = React.useState<{
    newOrders: number;
    newReviews: number;
  }>({ newOrders: 0, newReviews: 0 });
  const [userData, setUserData] = React.useState<
    { name: string; email: string } | undefined
  >(undefined);
  const [openLogOutModal, setOpenLogOutModal] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenModal = () => {
    setOpenLogOutModal(true);
  };

  const handleCloseModal = () => {
    setOpenLogOutModal(false);
  };

  const handleClickAuth = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleClickOpenModal();
  };

  const handleNewOrdersClick = () => {
    navigate('/sales/orders');
    handleClose();
  };

  const handleNewReviewsClick = () => {
    navigate('/clients/reviews');
    handleClose();
  };

  React.useEffect(() => {
    if (localStorage.getItem('token')) {
      const userToken = localStorage.getItem('token');
      token.set(userToken);
    } else if (sessionStorage.getItem('token')) {
      const userToken = sessionStorage.getItem('token');
      token.set(userToken);
    }
    (async () => {
      const userInfo = await fetchUserData(setLoggedIn);
      setUserData(userInfo);
    })();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const newNotificationsList = await fetchNewNotifications();
      setNewNotifications(newNotificationsList);
    };
    fetchData();
  }, [location]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className={cx(classes.appbar, darkTheme ? 'dark' : null)}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => toggleDrawer(!openDrawer)}
          >
            <MenuIcon
              className={cx(classes.menuToggler, darkTheme ? 'dark' : null)}
            />
          </IconButton>
          <Link to="/" className={cx(classes.logo, darkTheme ? 'dark' : null)}>
            <LogoIcon />
          </Link>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: 'auto',
            }}
          >
            <Button
              onClick={clearCache}
              variant="outlined"
              className={cx(classes.cashButton, darkTheme ? 'dark' : null)}
            >
              Очистити кеш
            </Button>
            <IconButton
              aria-label="show new notifications"
              color="inherit"
              className={cx(
                classes.headerNotificationsButton,
                darkTheme ? 'dark' : null
              )}
              onClick={handleMenu}
            >
              <Badge
                badgeContent={
                  newNotifications.newOrders + newNotifications.newReviews
                }
                color="error"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Menu
              id="notification-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {newNotifications.newOrders ? (
                <MenuItem
                  onClick={handleNewOrdersClick}
                  sx={{ display: 'flex', gap: 2 }}
                >
                  <span>Нові замовлення:</span>
                  {
                    <Badge
                      badgeContent={newNotifications.newOrders}
                      color="error"
                    />
                  }
                </MenuItem>
              ) : null}
              {newNotifications.newReviews ? (
                <MenuItem
                  onClick={handleNewReviewsClick}
                  sx={{ display: 'flex', gap: 2 }}
                >
                  <span>Нові відгуки:</span>
                  {
                    <Badge
                      badgeContent={newNotifications.newReviews}
                      color="error"
                    />
                  }
                </MenuItem>
              ) : null}
              {!newNotifications.newOrders && !newNotifications.newReviews && (
                <MenuItem onClick={handleClose}>
                  Нові відгуки та замовлення відсутні
                </MenuItem>
              )}
            </Menu>
            <IconButton
              aria-label="notification"
              aria-controls="notification-menu"
              aria-haspopup="true"
              color="inherit"
              className={cx(
                classes.headerAccountIconButton,
                darkTheme ? 'dark' : null
              )}
            >
              <AccountCircle style={{ width: '40px', height: '40px' }} />
            </IconButton>
            <Box className={classes.credentials}>
              <Typography
                component="p"
                className={cx(classes.userName, darkTheme ? 'dark' : null)}
                sx={{ mb: userData ? 0 : 1 }}
              >
                {userData ? (
                  userData.name
                ) : (
                  <Skeleton variant="rectangular" height={20} />
                )}
                {/* {userData.name} */}
                {/* <Skeleton variant="rectangular" height={20} /> */}
              </Typography>
              <Typography
                component="p"
                className={cx(classes.userEmail, darkTheme ? 'dark' : null)}
              >
                {userData ? (
                  userData.email
                ) : (
                  <Skeleton variant="rectangular" height={20} />
                )}
                {/* {userData.email} */}
                {/* <Skeleton variant="rectangular" height={20} /> */}
              </Typography>
            </Box>

            <IconButton
              aria-label="logout"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleClickAuth}
              color="inherit"
              className={cx(
                classes.headerLoginButton,
                darkTheme ? 'dark' : null
              )}
            >
              <LogoutIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {openLogOutModal && (
        <Modal
          shouldOpenModal={openLogOutModal}
          handleCloseModal={handleCloseModal}
          setLoggedIn={setLoggedIn}
          type={'logout'}
        />
      )}
    </Box>
  );
};

export default Header;
