import React from 'react';
import { navBarMenuItems } from '../components/NavBarMenu/NavBarMenuContent';

interface IRoute {
  path: string;
  component: React.ReactNode;
}

const allRoutes = navBarMenuItems.map(mainItem => {
  if (mainItem.items) {
    const newArray = mainItem.items?.map((subItem: any) => {
      const { Component, DataComponent, link, name, items } = subItem;
      if (items) {
        return items.map((childItem: any) => {
          const { Component, DataComponent, link, name } = childItem;
          return [
            {
              path: link,
              component: (
                <Component
                  pageName={name}
                  link={link}
                  parentPageName={mainItem.name}
                />
              ),
            },
            {
              path: `${link}/:type/:field/:value/:operator?/:secondType/:secondField/:secondValue`,
              component: (
                <Component
                  pageName={name}
                  link={link}
                  parentPageName={mainItem.name}
                />
              ),
            },
            {
              path: `${link}/:id/edit`,
              component: DataComponent ? (
                <DataComponent
                  initialLink={link}
                  pageName={name}
                  parentPageName={mainItem.name}
                />
              ) : null,
            },
            {
              path: `${link}/:type/:field/:value/:operator?/:id/edit`,
              component: DataComponent ? (
                <DataComponent
                  initialLink={link}
                  pageName={name}
                  parentPageName={mainItem.name}
                />
              ) : null,
            },
            {
              path: `${link}/:type/:field/:value/:operator?/:secondType/:secondField/:secondValue/:id/edit`,
              component: DataComponent ? (
                <DataComponent
                  initialLink={link}
                  pageName={name}
                  parentPageName={mainItem.name}
                />
              ) : null,
            },
            {
              path: `${link}/add`,
              component: DataComponent ? (
                <DataComponent
                  initialLink={link}
                  pageName={name}
                  parentPageName={mainItem.name}
                />
              ) : null,
            },
          ];
        });
      } else {
        return [
          {
            path: link,
            component: (
              <Component
                pageName={name}
                link={link}
                parentPageName={mainItem.name}
              />
            ),
          },
          {
            path: `${link}/:type/:field/:value/:operator?`,
            component: (
              <Component
                pageName={name}
                link={link}
                parentPageName={mainItem.name}
              />
            ),
          },
          {
            path: `${link}/:type/:field/:value/:operator?/:secondType/:secondField/:secondValue`,
            component: (
              <Component
                pageName={name}
                link={link}
                parentPageName={mainItem.name}
              />
            ),
          },
          {
            path: `${link}/:id/edit`,
            component: DataComponent ? (
              <DataComponent
                initialLink={link}
                pageName={name}
                parentPageName={mainItem.name}
              />
            ) : null,
          },
          {
            path: `${link}/:type/:field/:value/:operator?/:id/edit`,
            component: DataComponent ? (
              <DataComponent
                initialLink={link}
                pageName={name}
                parentPageName={mainItem.name}
              />
            ) : null,
          },
          {
            path: `${link}/:type/:field/:value/:operator?/:secondType/:secondField/:secondValue/:id/edit`,
            component: DataComponent ? (
              <DataComponent
                initialLink={link}
                pageName={name}
                parentPageName={mainItem.name}
              />
            ) : null,
          },
          {
            path: `${link}/add`,
            component: DataComponent ? (
              <DataComponent
                initialLink={link}
                pageName={name}
                parentPageName={mainItem.name}
              />
            ) : null,
          },
        ];
      }
    });
    const data = newArray.flat(1);
    return data;
  } else {
    const { Component, link } = mainItem;
    return [{ path: link, component: <Component /> }];
  }
});

export const routes: IRoute[] = allRoutes.flat(2);
