import { Box, Button } from '@mui/material';
import React from 'react';
import { usePagesDataCommonStyles } from '../../PagesEditData/PagesDataCommon/PagesDataCommon.styles';
import { Link } from 'react-router-dom';

const Error: React.FC = () => {
  const { classes } = usePagesDataCommonStyles();

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
      }}
    >
      <h1 style={{ fontSize: '24px', fontWeight: 500 }}>Сталася помилка</h1>
      <h1 style={{ fontSize: '24px', fontWeight: 500 }}>
        Спробуйте перезавантажити сторінку або перейти до замовлень
      </h1>
      <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center', mt: 2 }}>
        <Button
          onClick={handleReload}
          variant="contained"
          className={classes.reloadButton}
        >
          Перезавантажити
        </Button>
        <Button
          onClick={handleReload}
          variant="contained"
          className={classes.reloadButton}
        >
          <Link to="/sales/orders" style={{ color: 'inherit' }}>
            До замовлень
          </Link>
        </Button>
      </Box>
    </Box>
  );
};

export default Error;
