import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import NavBar from './components/NavBar';
import { GlobalContext } from './hooks/GlobalContext';
import { routes } from './routes';
// import Dashboard from './Pages/Dashboard';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import Toast from './components/Toast';
import LoginPage from './Pages/LoginPage';
import { loadFileManager } from './constants';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { usePageInitialLoading } from './hooks/usePageInitialLoading';
import { useConnection } from './hooks/useConnection';
import LogoIcon from './components/LogoIcon/LogoIcon';
import Error from './components/Error';

export const App: React.FC = () => {
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(true);
  const [darkTheme, setDarkTheme] = React.useState<boolean>(
    localStorage.getItem('THEME_MODE') === 'dark' ? true : false ?? false
  );
  const { isLoading } = usePageInitialLoading();

  const [loggedIn, setLoggedIn] = React.useState<boolean>(
    localStorage.getItem('token') || sessionStorage.getItem('token')
      ? true
      : false
  );

  const [rerenderComponent, setRerenderComponent] = React.useState(false);
  useConnection();

  const handleThemeClick = () => {
    setDarkTheme(!darkTheme);
  };

  const toggleDrawer = (open: boolean) => {
    setOpenDrawer(open);
  };

  React.useEffect(() => {
    loadFileManager();
  }, []);

  return (
    <>
      <GlobalContext.Provider
        value={{
          darkTheme,
          rerenderComponent,
          setRerenderComponent,
        }}
      >
        <Toast />
        {isLoading ? (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              animation: 'pulsate 3s ease 0s infinite normal none running',
            }}
          >
            <LogoIcon height="64px" />
          </div>
        ) : (
          <BrowserRouter>
            <ErrorBoundary fallback={<Error />}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <PrivateRoute user={loggedIn} redirectTo={'/login'}>
                      <NavBar
                        toggleDrawer={toggleDrawer}
                        openDrawer={openDrawer}
                        handleThemeClick={handleThemeClick}
                        darkTheme={darkTheme}
                        setLoggedIn={setLoggedIn}
                      />
                    </PrivateRoute>
                  }
                >
                  {/* <Route index element={<Dashboard />} /> */}
                  <Route
                    path="/"
                    element={<Navigate to="/sales/orders" replace />}
                  />
                  {routes.map((route, index) => {
                    const { path, component } = route;
                    return (
                      <Route key={index} path={path} element={component} />
                    );
                  })}
                  <Route path="*" element={<h1>Помилка посилання</h1>} />
                </Route>
                <Route
                  path="/login"
                  element={
                    <PublicRoute
                      restricted
                      redirectTo="/sales/orders"
                      user={loggedIn}
                    >
                      <LoginPage setLoggedIn={setLoggedIn} />
                    </PublicRoute>
                  }
                />
                <Route path="*" element={<h1>Помилка посилання</h1>} />
              </Routes>
            </ErrorBoundary>
          </BrowserRouter>
        )}
      </GlobalContext.Provider>
    </>
  );
};
